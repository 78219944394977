import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import PrintIcon from '@mui/icons-material/Print';
import { AppBar, Box, Fab, Toolbar, Typography } from '@mui/material';
import { useLocalStorageState } from 'ahooks';
import {
  filter,
  flatten,
  get,
  isEmpty,
  isEqual,
  map,
  multiply,
  pick,
  reverse,
  sortBy,
  sum,
  sumBy,
  uniqBy,
  uniqWith
} from 'lodash-es';
import React, { useRef } from 'react';
import { useLocation, useParams } from 'react-router';
import { ReactToPrint } from 'react-to-print';
import { useShareLink } from '~/api/share';
import { DAYS_PER_YEAR, MONTHS_PER_YEAR } from '~/constants/date';
import { ILookupPrimaryResponse } from '~/features/lookup/models/lookup';
import { date, formatApiDate, getEarlierDate, isDateBetween } from '~/lib/date';
import { calculateDailyEnergy, calculateDailyPriceByFeePeriod } from '~/models/common';
import { NMI, SiteClassification } from '~/models/meter';
import {
  EnergyPricingOfferTypes,
  IProjectOfferPrimaryResponse,
  ProjectOffer,
  ProjectOfferPeriod
} from '~/models/project';
import { GreenBillingCalculation } from '~/models/retailer';
import { User } from '~/models/user';
import BestOfferSection from '~/pages/dashboard/project/EAReport/BestOfferSection';
import ContractTermComparisonSection from '~/pages/dashboard/project/EAReport/ContractTermComparisonSection';
import CurrentVsNewBillSection from '~/pages/dashboard/project/EAReport/CurrentVsNewSection';
import { ReportContainer, Section, SectionContainer, Title } from '~/pages/dashboard/project/EAReport/style';
import SummarySection from '~/pages/dashboard/project/EAReport/SummarySection';
import { calculateGreenPrice } from '~/pages/dashboard/project/utils';
import reportCover from '~static/bg-solar.png';
import logoBlueSrc from '~static/logo-blue.png';
import SitesSection from './SitesSection';
import {
  CalculatedProjectOffer,
  CalculatedProjectOfferPeriod,
  EAReportData,
  EAReportGroupedMeter,
  EAReportMeter,
  ReportMeterWeighedAverageData,
  ReportProjectCalculatedMeters,
  ReportProjectMeter
} from './types';

const formatMeters = (data: EAReportMeter[]): ReportProjectCalculatedMeters => {
  const projectMeters = data
    .filter((v) => !!v)
    .map((meter) => {
      const annualPeakEnergy = get(meter, 'annualEstPeakEnergy') || get(meter.contract, 'contractPeakEnergy');
      const annualOffPeakEnergy = get(meter, 'annualEstOffPeakEnergy') || get(meter.contract, 'contractOffPeakEnergy');
      const annualShoulderEnergy =
        get(meter, 'annualEstShoulderEnergy') || get(meter.contract, 'contractShoulderEnergy');
      return {
        ...meter,
        annualData: {
          peakEnergy: annualPeakEnergy,
          offPeakEnergy: annualOffPeakEnergy,
          shoulderEnergy: annualShoulderEnergy,
          totalEnergy: sum([annualPeakEnergy, annualOffPeakEnergy, annualShoulderEnergy]),
          retailerFeePerMeter: meter.contract.retailSupplyCharge || 0,
          meteringChargePerMeter: sum([meter.contract.meteringCharge, meter.contract.meteringServiceFeePrice])
        }
      };
    });

  if (!projectMeters || projectMeters?.length === 0) {
    return {} as ReportProjectCalculatedMeters;
  }

  const currProjectMeterContract = projectMeters[0].contract;
  const currProjectMeterContractPeriod = projectMeters[0].contractPeriod;
  const annualTotalEnergyAcrossAllSites = sumBy(projectMeters, 'annualData.totalEnergy');
  const weighedPeakRate =
    sumBy(projectMeters, (v) => v.annualData.peakEnergy ?? 0) > 0
      ? sumBy(projectMeters, (v) => multiply(v.annualData.peakEnergy ?? 0, v.contractPeriod?.peakRate ?? 0)) /
        sumBy(projectMeters, (v) => v.annualData.peakEnergy ?? 0)
      : 0;
  const weighedOffPeakRate =
    sumBy(projectMeters, (v) => v.annualData.offPeakEnergy ?? 0) > 0
      ? sumBy(projectMeters, (v) => multiply(v.annualData.offPeakEnergy ?? 0, v.contractPeriod?.offPeakRate ?? 0)) /
        sumBy(projectMeters, (v) => v.annualData.offPeakEnergy ?? 0)
      : 0;
  const weighedShoulderRate =
    sumBy(projectMeters, (v) => v.annualData.shoulderEnergy ?? 0) > 0
      ? sumBy(projectMeters, (v) => multiply(v.annualData.shoulderEnergy ?? 0, v.contractPeriod?.shoulderRate ?? 0)) /
        sumBy(projectMeters, (v) => v.annualData.shoulderEnergy ?? 0)
      : 0;
  const weighedLretRate =
    sumBy(projectMeters, (v) => multiply(v.annualData.totalEnergy ?? 0, v.contractPeriod?.lretRate ?? 0)) /
    annualTotalEnergyAcrossAllSites;
  const weighedVeetRate =
    sumBy(projectMeters, (v) => multiply(v.annualData.totalEnergy ?? 0, v.contractPeriod?.veetRate ?? 0)) /
    annualTotalEnergyAcrossAllSites;
  const weighedEssRate =
    sumBy(projectMeters, (v) => multiply(v.annualData.totalEnergy ?? 0, v.contractPeriod?.essRate ?? 0)) /
    annualTotalEnergyAcrossAllSites;
  const weighedSrecRate =
    sumBy(projectMeters, (v) => multiply(v.annualData.totalEnergy ?? 0, v.contractPeriod?.srecRate ?? 0)) /
    annualTotalEnergyAcrossAllSites;
  const weighedAeeisRate =
    sumBy(projectMeters, (v) => multiply(v.annualData.totalEnergy ?? 0, v.contractPeriod?.aeeisRate ?? 0)) /
    annualTotalEnergyAcrossAllSites;
  const weighedAncillaryCharge =
    sumBy(projectMeters, (v) => multiply(v.annualData.totalEnergy ?? 0, v.ancillaryCharge ?? 0)) /
    annualTotalEnergyAcrossAllSites;
  const weighedParticipantCharge =
    sumBy(projectMeters, (v) => multiply(v.annualData.totalEnergy ?? 0, v.participantCharge ?? 0)) /
    annualTotalEnergyAcrossAllSites;
  const weighedAnnualRetailerFee = sumBy(projectMeters, (v) => v.annualData.retailerFeePerMeter) / projectMeters.length;
  const weighedAnnualMeteringCharge =
    sumBy(projectMeters, (v) => v.annualData.meteringChargePerMeter) / projectMeters.length;

  const lwaDLF =
    sumBy(projectMeters, (meter) => (meter.dlf || meter.tlf || 1) * meter.annualData.totalEnergy) /
    annualTotalEnergyAcrossAllSites;
  const lwaMLF =
    sumBy(projectMeters, (meter) => (meter.mlf || meter.tlf || 1) * meter.annualData.totalEnergy) /
    annualTotalEnergyAcrossAllSites;
  const lossFactor =
    currProjectMeterContract.retailer?.greenBilling === GreenBillingCalculation.DLF_MLF ? lwaDLF * lwaMLF : lwaDLF;

  const calculatedMeters = projectMeters
    .map((meter) => ({
      ...meter,
      contractPeriod: currProjectMeterContractPeriod,
      annualData: {
        ...meter.annualData,
        networkStanding: NMI.from(meter).calculateAnnualNetworkStanding(),
        networkChargesEnergy: NMI.from(meter).calculateAnnualNetworkChargesEnergy(meter.annualData.totalEnergy),
        networkChargesDemand: NMI.from(meter).calculateAnnualNetworkChargesDemand()
      }
    }))
    .map((meter) => {
      const lossFactor =
        meter.contract.retailer?.greenBilling === GreenBillingCalculation.DLF_MLF
          ? (meter.dlf ?? 1) * (meter.mlf ?? 1)
          : meter.dlf ?? 1;
      const energyCost =
        (sum([
          meter.annualData.peakEnergy * (meter.contractPeriod.peakRate ?? 0),
          meter.annualData.offPeakEnergy * (meter.contractPeriod.offPeakRate ?? 0),
          meter.annualData.shoulderEnergy * (meter.contractPeriod.shoulderRate ?? 0)
        ]) *
          (meter.contract?.contractType === SiteClassification.TARIFF ? 1 : lwaDLF * lwaMLF)) /
        100;
      const renewableCost =
        (sum([
          meter.contractPeriod?.aeeisRate,
          meter.contractPeriod?.essRate,
          meter.contractPeriod?.lretRate,
          meter.contractPeriod?.veetRate,
          meter.contractPeriod?.srecRate
        ]) *
          meter.annualData.totalEnergy *
          lossFactor) /
        100;

      const regulatedCost =
        (sum([meter.ancillaryCharge ?? 0, meter.participantCharge ?? 0]) * meter.annualData.totalEnergy * lossFactor) /
        100;
      const annualRetailerFee = (meter.annualData.retailerFeePerMeter || 0) * meter.numOfMeters;
      const annualMeteringCharge = meter.annualData.meteringChargePerMeter * meter.numOfMeters;
      const contestableCost = sum([energyCost, renewableCost, annualRetailerFee, annualMeteringCharge]);

      return {
        ...meter,
        annualData: {
          ...meter.annualData,
          lossFactor,
          energyCost,
          renewableCost,
          regulatedCost,
          annualRetailerFee,
          annualMeteringCharge,
          contestableCost
        }
      };
    });

  const currentEnergyCost =
    (sum([
      multiply(
        weighedPeakRate,
        sumBy(projectMeters, (v) => v.annualData.peakEnergy ?? 0)
      ),
      multiply(
        weighedOffPeakRate,
        sumBy(projectMeters, (v) => v.annualData.offPeakEnergy ?? 0)
      ),
      multiply(
        weighedShoulderRate,
        sumBy(projectMeters, (v) => v.annualData.shoulderEnergy ?? 0)
      )
    ]) *
      (currProjectMeterContract.contractType === SiteClassification.TARIFF ? 1 : lwaDLF * lwaMLF)) /
    100;
  const currentRenewableCost =
    (sum([weighedAeeisRate, weighedEssRate, weighedLretRate, weighedVeetRate, weighedSrecRate]) *
      annualTotalEnergyAcrossAllSites *
      lossFactor) /
    100;
  const currentRegulatedCost =
    (sum([weighedAncillaryCharge ?? 0, weighedParticipantCharge ?? 0]) * annualTotalEnergyAcrossAllSites * lossFactor) /
    100;
  const currentAnnualRetailerFee = weighedAnnualRetailerFee * sumBy(calculatedMeters, 'numOfMeters');
  const currentAnnualMeteringCharge = weighedAnnualMeteringCharge * sumBy(calculatedMeters, 'numOfMeters');

  const currentContestableCost = sum([
    currentEnergyCost,
    currentRenewableCost,
    currentAnnualRetailerFee,
    currentAnnualMeteringCharge
  ]);
  const currentAvgCostPerKwh = (currentContestableCost / annualTotalEnergyAcrossAllSites) * 100;

  return {
    meters: calculatedMeters as ReportProjectMeter[],
    lwaDLF,
    lwaMLF,
    lossFactor,
    annualData: {
      totalEnergy: annualTotalEnergyAcrossAllSites,
      networkStanding: sumBy(calculatedMeters, 'annualData.networkStanding'),
      networkChargesEnergy: sumBy(calculatedMeters, 'annualData.networkChargesEnergy'),
      networkChargesDemand: sumBy(calculatedMeters, 'annualData.networkChargesDemand'),
      retailerFee: currentAnnualRetailerFee,
      meteringCharge: currentAnnualMeteringCharge
    },
    weighedAverageData: {
      peakRate: weighedPeakRate,
      offPeakRate: weighedOffPeakRate,
      shoulderRate: weighedShoulderRate,
      lret: weighedLretRate,
      sres: weighedSrecRate,
      ess: weighedEssRate,
      veet: weighedVeetRate,
      aeeis: weighedAeeisRate,
      ancillaryCharge: weighedAncillaryCharge,
      participantCharge: weighedParticipantCharge,
      annualRetailerFee: weighedAnnualRetailerFee,
      annualMeteringCharge: weighedAnnualMeteringCharge
    },
    currentEnergyCost,
    currentRegulatedCost,
    currentRenewableCost,
    currentContestableCost,
    currentAvgCostPerKwh
  };
};

const calculateOffersForMeters = ({
  projectMeters,
  projectRawOffers,
  greenPriceLookUps,
  lwaDLF,
  lwaMLF,
  weighedAverageData
}: {
  projectMeters: ReportProjectMeter[];
  projectRawOffers: (IProjectOfferPrimaryResponse & { isBest: boolean })[];
  greenPriceLookUps: ILookupPrimaryResponse[];
  lwaDLF: number;
  lwaMLF: number;
  weighedAverageData: ReportMeterWeighedAverageData;
}) => {
  if (!projectMeters || projectMeters?.length === 0) {
    return [] as CalculatedProjectOffer[];
  }
  const currentDailyMeteringCharge = calculateDailyPriceByFeePeriod(weighedAverageData.annualMeteringCharge);
  const currentDailyRetailerFee = calculateDailyPriceByFeePeriod(weighedAverageData.annualRetailerFee);
  const uniqueSortedPeriods = uniqWith(
    sortBy(flatten(projectRawOffers.map((v) => v.periods)), (v) => date(v?.endDate).valueOf()).map((v) =>
      pick(v, ['startDate', 'endDate'])
    ),
    isEqual
  );

  const averageDailyPeakEnergy = sumBy(projectMeters, (v) => calculateDailyEnergy(v.annualData.peakEnergy));
  const averageDailyShoulderEnergy = sumBy(projectMeters, (v) => calculateDailyEnergy(v.annualData.shoulderEnergy));
  const averageDailyOffPeakEnergy = sumBy(projectMeters, (v) => calculateDailyEnergy(v.annualData.offPeakEnergy));
  const averageDailyTotalEnergy = sum([averageDailyPeakEnergy, averageDailyShoulderEnergy, averageDailyOffPeakEnergy]);

  const calculatePeriodWithCosts = (offer: ProjectOffer, period: ProjectOfferPeriod) => {
    const startYearGreenPrice = greenPriceLookUps?.find((v) => v.year === period.startYear);
    const endYearGreenPrice = greenPriceLookUps?.find((v) => v.year === period.endYear);
    const numOfMeters = sumBy(projectMeters, 'numOfMeters');
    const lossFactor = offer.greenBilling === GreenBillingCalculation.DLF_MLF ? lwaDLF * lwaMLF : lwaDLF;

    const lret = calculateGreenPrice('lret', period, offer, startYearGreenPrice, endYearGreenPrice);
    const sres = calculateGreenPrice('sres', period, offer, startYearGreenPrice, endYearGreenPrice);
    const ess = calculateGreenPrice('ess', period, offer, startYearGreenPrice, endYearGreenPrice);
    const veet = calculateGreenPrice('veet', period, offer, startYearGreenPrice, endYearGreenPrice);
    const aeeis = calculateGreenPrice('aeeis', period, offer, startYearGreenPrice, endYearGreenPrice);

    const energyCost =
      (period.numOfDays / 100) *
      sumBy(
        projectMeters,
        (meter) =>
          sum([
            period.peak * calculateDailyEnergy(meter.annualData.peakEnergy),
            period.shoulder * calculateDailyEnergy(meter.annualData.shoulderEnergy),
            period.offPeak * calculateDailyEnergy(meter.annualData.offPeakEnergy)
          ]) * (meter.contract?.contractType === SiteClassification.TARIFF ? 1 : lwaDLF * lwaMLF)
      );

    const renewableCost =
      sum([lret, sres, ess, veet, aeeis]) * averageDailyTotalEnergy * lossFactor * (period.numOfDays / 100);

    const regulatedCost =
      (sum([weighedAverageData.ancillaryCharge ?? 0, weighedAverageData.participantCharge ?? 0]) *
        averageDailyTotalEnergy *
        period.numOfDays *
        lossFactor) /
      100;

    const networkCost =
      (sumBy(projectMeters, (meter) =>
        sum([
          meter.annualData.networkStanding,
          meter.annualData.networkChargesEnergy,
          meter.annualData.networkChargesDemand
        ])
      ) /
        DAYS_PER_YEAR) *
      period.numOfDays;

    const meteringCharge = (offer.dailyMeteringFee || currentDailyMeteringCharge) * period.numOfDays * numOfMeters;

    const retailerServiceFee = (offer.dailyServiceFee || currentDailyRetailerFee) * period.numOfDays * numOfMeters;

    const contestableCost = sum([energyCost, renewableCost, meteringCharge, retailerServiceFee]);

    return {
      ...period,
      numOfDays: period.numOfDays,
      startYear: period.startYear,
      endYear: period.endYear,
      daysStart: period.daysStart,
      daysEnd: period.daysEnd,
      lret,
      sres,
      ess,
      veet,
      aeeis,
      energyCost,
      renewableCost,
      regulatedCost,
      networkCost,
      meteringCharge,
      retailerServiceFee,
      contestableCost,
      totalCosts: contestableCost + networkCost + regulatedCost
    } as CalculatedProjectOfferPeriod;
  };

  const projectOffers = projectRawOffers.map((rawOffer) => {
    const offer = ProjectOffer.from(rawOffer);

    const calculatedPeriods = offer.periods.map((rawPeriod) => {
      return uniqueSortedPeriods.reduce((prev, cur) => {
        if (
          prev[prev.length - 1] &&
          (date(prev[prev.length - 1].endDate).valueOf() >= date(rawPeriod.endDate).valueOf() ||
            date(prev[prev.length - 1].endDate).valueOf() >= date(cur.endDate).valueOf())
        ) {
          return prev;
        }

        const startDate = prev[prev.length - 1]
          ? formatApiDate(date(prev[prev.length - 1].endDate).add(1, 'day'))
          : formatApiDate(getEarlierDate(cur.startDate!, rawPeriod.startDate));

        const matchedPeriod = offer.periods.find((v) => isDateBetween(cur.endDate, v.startDate, v.endDate));
        const splitPeriod = ProjectOfferPeriod.from({
          ...rawPeriod,
          startDate,
          endDate: cur.endDate,
          ...pick(rawPeriod.offerType === EnergyPricingOfferTypes.FLAT ? rawPeriod : matchedPeriod, [
            'peak',
            'offPeak',
            'shoulder'
          ]),
          ...pick(
            rawPeriod.offerType === EnergyPricingOfferTypes.FLAT &&
              offer.periods.filter((v) => v.periodName === rawPeriod.periodName).length === 1
              ? rawPeriod
              : matchedPeriod,
            ['lret', 'sres', 'ess', 'veet', 'aeeis']
          )
        });

        return [
          ...prev,
          {
            ...calculatePeriodWithCosts(offer, splitPeriod)
          } as unknown as ProjectOfferPeriod
        ];
      }, [] as ProjectOfferPeriod[]);
    });

    const periods = offer.periods.map((period) => {
      const offerCalculatedPeriods = calculatedPeriods.find((v) => v[0].id === period.id);
      const periodContestableCosts = sumBy(offerCalculatedPeriods, 'contestableCost');
      const periodRenewalCosts = sumBy(offerCalculatedPeriods, 'renewableCost');
      const periodEnergyCosts = sumBy(offerCalculatedPeriods, 'energyCost');
      const periodRegulatedCost = sumBy(offerCalculatedPeriods, 'regulatedCost');
      const periodMeteringCharge = sumBy(offerCalculatedPeriods, 'meteringCharge');
      const periodNetworkCost = sumBy(offerCalculatedPeriods, 'networkCost');
      const periodTotalCosts = sumBy(offerCalculatedPeriods, 'totalCosts');
      const periodRetailerServiceFee = sumBy(offerCalculatedPeriods, 'retailerServiceFee');
      const annualTotalCosts = (periodTotalCosts / period.periodName) * MONTHS_PER_YEAR;
      const totalNumOfDays = sumBy(offerCalculatedPeriods, 'numOfDays');
      const avgCostPerKwh = (periodTotalCosts / (averageDailyTotalEnergy * totalNumOfDays)) * 100;
      const avgContestableCostsPerKwh = (periodContestableCosts / (averageDailyTotalEnergy * totalNumOfDays)) * 100;

      return {
        ...period,
        periodContestableCosts,
        periodRenewalCosts,
        periodRegulatedCost,
        periodEnergyCosts,
        periodMeteringCharge,
        periodRetailerServiceFee,
        annualTotalCosts,
        totalNumOfDays,
        avgContestableCostsPerKwh,
        avgCostPerKwh,
        periodNetworkCost,
        periodTotalCosts,
        splitPeriods: offerCalculatedPeriods,
        lret:
          sumBy(offerCalculatedPeriods, (p) => (p.lret ?? 0) * p.numOfDays) /
          sumBy(offerCalculatedPeriods, 'numOfDays'),
        sres:
          sumBy(offerCalculatedPeriods, (p) => (p.sres ?? 0) * p.numOfDays) /
          sumBy(offerCalculatedPeriods, 'numOfDays'),
        ess:
          sumBy(offerCalculatedPeriods, (p) => (p.ess ?? 0) * p.numOfDays) / sumBy(offerCalculatedPeriods, 'numOfDays'),
        veet:
          sumBy(offerCalculatedPeriods, (p) => (p.veet ?? 0) * p.numOfDays) /
          sumBy(offerCalculatedPeriods, 'numOfDays'),
        aeeis:
          sumBy(offerCalculatedPeriods, (p) => (p.aeeis ?? 0) * p.numOfDays) /
          sumBy(offerCalculatedPeriods, 'numOfDays'),
        peak:
          sumBy(offerCalculatedPeriods, (p) => (p.peak ?? 0) * averageDailyPeakEnergy * p.numOfDays) > 0
            ? sumBy(offerCalculatedPeriods, (p) => (p.peak ?? 0) * averageDailyPeakEnergy * p.numOfDays) /
              sumBy(offerCalculatedPeriods, (p) => averageDailyPeakEnergy * p.numOfDays)
            : 0,
        offPeak:
          sumBy(offerCalculatedPeriods, (p) => (p.offPeak ?? 0) * averageDailyOffPeakEnergy * p.numOfDays) > 0
            ? sumBy(offerCalculatedPeriods, (p) => (p.offPeak ?? 0) * averageDailyOffPeakEnergy * p.numOfDays) /
              sumBy(offerCalculatedPeriods, (p) => averageDailyOffPeakEnergy * p.numOfDays)
            : 0,
        shoulder:
          sumBy(offerCalculatedPeriods, (p) => (p.shoulder ?? 0) * averageDailyShoulderEnergy * p.numOfDays) > 0
            ? sumBy(offerCalculatedPeriods, (p) => (p.shoulder ?? 0) * averageDailyShoulderEnergy * p.numOfDays) /
              sumBy(offerCalculatedPeriods, (p) => averageDailyShoulderEnergy * p.numOfDays)
            : 0
      };
    });

    return {
      ...offer,
      dailyMeteringFee: offer.dailyMeteringFee,
      dailySuppDMAOrRPFee: offer.dailySuppDMAOrRPFee,
      dailyServiceFee: offer.dailyServiceFee,
      periods: reverse(uniqBy(reverse(periods), 'periodName'))
    };
  });
  return projectOffers;
};

const ProjectEAReportPreview: React.FC = () => {
  const { pathname, search } = useLocation();
  const { id } = useParams();
  const componentRef = useRef<HTMLDivElement>(null);

  let data;
  if (pathname.includes('share/') && id) {
    const { data: shareLink } = useShareLink(id, search.split('pwd=')[1]);
    data = (shareLink?.metadata ?? {}) as unknown as EAReportData;
  } else {
    const [projectEAReportData] = useLocalStorageState<EAReportData>('projectEAReportData');
    data = projectEAReportData;
  }

  if (isEmpty(data)) {
    return null;
  }

  const {
    reportType,
    company,
    companyName,
    contactUser,
    meterGroups,
    meters,
    offers,
    greenPriceLookUps,
    isPrintVersion = false
  } = data;

  const groupedMeters = meterGroups.map((group) => {
    const formattedMeters = formatMeters(
      meters.filter((meter) => group.meterIds.includes(meter.id)).map((v) => v as unknown as EAReportMeter)
    );

    const calculatedOffers = calculateOffersForMeters({
      ...pick(formattedMeters, ['currentAvgCostPerKwh', 'lwaDLF', 'lwaMLF', 'annualData', 'weighedAverageData']),
      projectMeters: formattedMeters.meters as unknown as ReportProjectMeter[],
      projectRawOffers: filter(offers, (offer) => group.offerIds.includes(offer.id!)).map((offer) => ({
        ...offer,
        isBest: map(offer.periods, 'id').includes(group.recommendedPeriodId || ''),
        periods: filter(offer.periods, (period) => group.displayOfferPeriodIds.includes(period.id)).map((period) => ({
          ...period,
          isBest: period.periodName === offer.periods.find((p) => p.id === group.recommendedPeriodId)?.periodName
        }))
      })),
      greenPriceLookUps: greenPriceLookUps || []
    });

    return {
      ...formattedMeters,
      meters: formattedMeters.meters as ReportProjectMeter[],
      offers: calculatedOffers as CalculatedProjectOffer[],
      recommendedPeriodId: group.recommendedPeriodId,
      name: group.name
    } as unknown as EAReportGroupedMeter;
  });

  return (
    <>
      {isPrintVersion && (
        <ReactToPrint
          trigger={() => (
            <Fab
              color="primary"
              aria-label="Print"
              sx={{ position: 'fixed', bottom: 16, right: 16, bgcolor: 'brand.main' }}
            >
              <PrintIcon />
            </Fab>
          )}
          content={() => componentRef.current}
          pageStyle="@page { size: A4 landscape;  margin: 10mm 0 10mm 0; }"
        />
      )}

      <ReportContainer ref={componentRef}>
        <AppBar component="nav" sx={{ bgcolor: 'common.black' }}>
          <Toolbar>
            <SectionContainer sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Box component="img" src={logoBlueSrc} sx={{ height: 100 }} />
              </Box>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                  <Typography
                    component="a"
                    variant="h6"
                    color="brand.main"
                    href={`tel:1300118834`}
                    sx={{ display: 'flex', alignItems: 'center', pl: 2 }}
                  >
                    <CallIcon /> &nbsp;1300 11 88 34
                  </Typography>
                  <Typography
                    component="a"
                    variant="h6"
                    color="brand.main"
                    href={`mailto:1300118834`}
                    sx={{ display: 'flex', alignItems: 'center', pl: 2 }}
                  >
                    <EmailIcon /> &nbsp;info@tteg.com.au
                  </Typography>
                  <Typography
                    component="a"
                    variant="h6"
                    color="brand.main"
                    href="https://tteg.com.au"
                    target="_blank"
                    sx={{ display: 'flex', alignItems: 'center', pl: 2 }}
                  >
                    <LanguageIcon /> &nbsp;tteg.com.au
                  </Typography>
                </Box>
              </Box>
            </SectionContainer>
          </Toolbar>
        </AppBar>

        <Toolbar />

        <Section
          sx={{
            backgroundImage: `url(${reportCover})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundAttachment: 'fixed',
            height: '100%',
            p: 0
          }}
        >
          <SectionContainer sx={{ pt: 15, position: 'relative', height: 'calc(100vh - 64px)', minHeight: 500 }}>
            <Title variant="h1" color="common.black">
              ELECTRICITY
              <br />
              EVALUATION
              <br />& ANALYSIS
            </Title>
            <Box sx={{ position: 'absolute', right: 32, bottom: 32 }}>
              <Box sx={{ bgcolor: 'common.black', p: 2, mb: 2, color: 'brand.main' }}>
                <Typography variant="h6">Customer name: {companyName || company.tradingName}</Typography>
                <Typography variant="h6">ABN/ACN: {[company.abn, company.acn].filter((v) => !!v).join('/')}</Typography>
              </Box>

              <Box sx={{ bgcolor: 'common.white', p: 2, color: 'brand.main' }}>
                <Typography variant="h6">Prepared by:</Typography>
                <Typography variant="h6">{User.from(contactUser).fullName}</Typography>
              </Box>
            </Box>
          </SectionContainer>
        </Section>

        <SummarySection groupedMeters={groupedMeters} />

        {groupedMeters.map((groupedMeter, index) => (
          <Box key={`group-meter-box-${index}`}>
            {!isPrintVersion && (
              <BestOfferSection name={groupedMeter.name} meters={groupedMeter.meters} offers={groupedMeter.offers} />
            )}

            <ContractTermComparisonSection
              name={groupedMeter.name}
              meters={groupedMeter.meters}
              offers={groupedMeter.offers}
            />

            {!isPrintVersion && (
              <CurrentVsNewBillSection name={groupedMeter.name} groupedMeter={groupedMeter} reportType={reportType} />
            )}
          </Box>
        ))}

        <SitesSection groupedMeters={groupedMeters} />
      </ReportContainer>
    </>
  );
};

export default ProjectEAReportPreview;
