import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Box, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { capitalize, filter, flatten, last, map, minBy, sortBy } from 'lodash-es';
import React from 'react';
import NumberText from '~/components/NumberText';
import { formatUiDateTime } from '~/lib/date';
import { EnergyPricingOfferTypes } from '~/models/project';
import {
  NoPageBreakBefore,
  PageBreak,
  Section,
  SectionContainer,
  StyledTableCell,
  StyledTableRow,
  Title,
  TitleUnderline
} from '~/pages/dashboard/project/EAReport/style';
import { CalculatedProjectOffer, EAReportMeter } from './types';

const OFFER_COLOR = [
  { bgColor: 'common.black', textColor: 'brand.main', textSecondaryColor: 'grey.A700' },
  { bgColor: 'grey.A700', textColor: 'common.white', textSecondaryColor: 'common.black' }
];

const BEST_OFFER_COLOR = { bgColor: 'brand.main', textColor: 'common.black', textSecondaryColor: 'common.white' };

const BestOfferSection: React.FC<{ meters: EAReportMeter[]; offers: CalculatedProjectOffer[]; name: string }> = ({
  meters,
  offers,
  name
}) => {
  const bestPeriodName = flatten(map(offers, 'periods')).find((period) => period.isBest)?.periodName;
  const sortedOffers = sortBy(
    filter(
      map(offers, (offer) => ({ ...offer, periods: offer.periods.filter((p) => p.periodName === bestPeriodName) })),
      (offer) => offer.periods.length > 0
    ),
    (offer) => minBy(offer.periods, 'annualTotalCosts')?.annualTotalCosts
  );

  const getOfferColor = (index: number, isBest: boolean) => {
    return isBest ? BEST_OFFER_COLOR : OFFER_COLOR[index % 2];
  };

  return (
    <>
      <Section sx={PageBreak}>
        <SectionContainer sx={{ textAlign: 'center' }}>
          <Title variant="h4">BEST OFFERS</Title>
          <TitleUnderline />
          <Typography variant="h5">{name || map(meters, 'meterNumber').join(', ')}</Typography>
        </SectionContainer>
      </Section>

      <Section>
        <SectionContainer>
          <Typography variant="h6" gutterBottom>
            Recommended Term -&nbsp;
            {bestPeriodName}
            &nbsp;months
          </Typography>
          <Typography variant="h6" color="#49BCE8" gutterBottom>
            TOTAL CONTESTABLE SPEND & ENERGY RATES
          </Typography>
          <br />
          <br />
          <Box sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 4,
                flexWrap: 'wrap',
                mt: -8,
                justifyContent: 'center'
              }}
            >
              {sortedOffers.map((offer, offerIndex) => (
                <Stack
                  key={`best-offer-${map(meters, 'meterNumber').join('-')}-${offer.id}`}
                  sx={{
                    bgcolor: getOfferColor(offerIndex, offer.isBest).bgColor,
                    width: '24%',
                    p: '0 20px 30px',
                    mr: '2%',
                    mt: 8,
                    textAlign: 'center',
                    pageBreakInside: 'avoid'
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: '100%',
                      bgcolor: getOfferColor(offerIndex, offer.isBest).bgColor,
                      width: 100,
                      height: 100,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: 'auto',
                      top: -40,
                      overflow: 'hidden',
                      position: 'relative',
                      transition: 'all 0.3s ease-in-out',
                      boxShadow: '0 0 0 8px #f0f0f0'
                    }}
                  >
                    <Typography variant="h5" color={getOfferColor(offerIndex, offer.isBest).textColor}>
                      {offerIndex + 1}
                    </Typography>
                  </Box>

                  <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    {offer.isBest && <TaskAltIcon fontSize="large" />}
                    <Box>
                      <Typography variant="h6" color={getOfferColor(offerIndex, offer.isBest).textColor}>
                        {offer.retailer.name}
                      </Typography>
                      <Typography variant="h6" color={getOfferColor(offerIndex, offer.isBest).textColor}>
                        $
                        <NumberText
                          value={minBy(offer.periods, 'annualTotalCosts')?.periodContestableCosts}
                          precision={0}
                        />
                      </Typography>
                    </Box>
                  </Stack>
                  <br />
                  <Typography variant="subtitle2" color={getOfferColor(offerIndex, offer.isBest).textSecondaryColor}>
                    {minBy(offer.periods, 'annualTotalCosts')?.offerType === EnergyPricingOfferTypes.STEPPED && 'Avg.'}
                    &nbsp;Peak:&nbsp;
                    <NumberText value={minBy(offer.periods, 'annualTotalCosts')?.peak} precision={3} />
                    &nbsp;c/kWh
                    <br />
                    {minBy(offer.periods, 'annualTotalCosts')?.offerType === EnergyPricingOfferTypes.STEPPED && 'Avg.'}
                    &nbsp;Off Peak:&nbsp;
                    <NumberText value={minBy(offer.periods, 'annualTotalCosts')?.offPeak} precision={3} />
                    &nbsp;c/kWh
                    <br />
                    <i>Valid till {formatUiDateTime(offer.validityEndDateTime)}</i>
                  </Typography>
                  {offerIndex + 1 <= 3 && <Box sx={{ pageBreakAfter: 'always', clear: 'both', height: 0 }}></Box>}
                </Stack>
              ))}
            </Box>
          </Box>

          {offers.map((offer, offerIndex) => (
            <Box key={offerIndex} sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                {offer.retailer.name}
              </Typography>

              <TableContainer sx={NoPageBreakBefore}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>{offer.retailer.name}</StyledTableCell>
                      <StyledTableCell>Rate Type</StyledTableCell>
                      <StyledTableCell colSpan={3}>kWh Commodity Unit Rate</StyledTableCell>
                      <StyledTableCell>Contract cost</StyledTableCell>
                      <StyledTableCell>Environmental Costs</StyledTableCell>
                      <StyledTableCell>Retailer & Metering Costs</StyledTableCell>
                      <StyledTableCell>Contestable Total Spend</StyledTableCell>
                      <StyledTableCell>Contestable Cost Per Unit</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ bgcolor: 'brand.main' }}>
                      <StyledTableCell sx={{ color: 'common.white' }}>
                        Term
                        <br />
                        (Months)
                      </StyledTableCell>
                      <StyledTableCell sx={{ color: 'common.white' }}>Offered</StyledTableCell>
                      <StyledTableCell sx={{ color: 'common.white' }}>Peak</StyledTableCell>
                      <StyledTableCell sx={{ color: 'common.white' }}>OffPeak</StyledTableCell>
                      <StyledTableCell sx={{ color: 'common.white' }}>Shoulder</StyledTableCell>
                      <StyledTableCell sx={{ color: 'common.white' }}>Ex GST</StyledTableCell>
                      <StyledTableCell sx={{ color: 'common.white' }}>Ex GST</StyledTableCell>
                      <StyledTableCell sx={{ color: 'common.white' }}>Ex GST</StyledTableCell>
                      <StyledTableCell sx={{ color: 'common.white' }}>Ex GST</StyledTableCell>
                      <StyledTableCell sx={{ color: 'common.white' }}>c/kWh</StyledTableCell>
                    </TableRow>
                    {map(offer.periods, (period) => (
                      <StyledTableRow
                        key={`${offer.retailer.name}-${period.periodName}`}
                        className={period.isBest === true ? 'highlight' : ''}
                      >
                        <StyledTableCell component="th" scope="row">
                          {period.periodName}
                        </StyledTableCell>

                        <StyledTableCell>
                          {capitalize(period.offerType === 'FLAT' ? 'SMOOTH' : period.offerType)}
                        </StyledTableCell>
                        <StyledTableCell>
                          <NumberText value={last(period.splitPeriods)?.peak} precision={5} na="-" />
                        </StyledTableCell>
                        <StyledTableCell>
                          <NumberText value={last(period.splitPeriods)?.offPeak} precision={5} na="-" />
                        </StyledTableCell>
                        <StyledTableCell>
                          <NumberText value={last(period.splitPeriods)?.shoulder} precision={5} na="-" />
                        </StyledTableCell>
                        <StyledTableCell>
                          $<NumberText value={period.periodEnergyCosts} precision={0} />
                        </StyledTableCell>
                        <StyledTableCell>
                          $<NumberText value={period.periodRenewalCosts} precision={0} />
                        </StyledTableCell>
                        <StyledTableCell>
                          $
                          <NumberText
                            value={period.periodRetailerServiceFee + period.periodMeteringCharge}
                            precision={0}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          $<NumberText value={period.periodContestableCosts} precision={0} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <NumberText value={period.avgContestableCostsPerKwh} precision={2} />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}
        </SectionContainer>
      </Section>
    </>
  );
};

export default BestOfferSection;
